import { Component, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { AuthService, HelpdeskCaseService } from '../services';
import { environment } from '../../environments/environment';
declare const $: any;

// Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    children?: ChildrenItems[];
    roles: Array<string>;
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
    rolesSub: Array<string>;
}

// Menu Items
export const ROUTES: RouteInfo[] = [{
    path: '/dashboard',
    title: 'Dashboard',
    type: 'link',
    icontype: 'dashboard',
    roles: [],
    children: []
}, {
    path: '/case',
    title: 'จัดการข้อมูลรับเรื่อง',
    type: 'sub',
    icontype: 'layers',
    collapse: 'case',
    roles: ['admin', 'helpdesk', 'interpreter', 'technical', 'supervisor'],
    children: [
        { path: 'manage', title: 'นำเข้าข้อมูลรับเรื่อง', ab: 'CM' , rolesSub: ['admin', 'helpdesk', 'technical']},
        { path: 'received', title: 'รายการที่กำลังดำเนินการ', ab: 'CR', rolesSub: ['admin', 'helpdesk', 'technical', 'interpreter', 'supervisor']},
        { path: 'waiting', title: 'รายการที่รอยืนยัน', ab: 'CW', rolesSub: ['admin', 'helpdesk', 'technical', 'interpreter', 'supervisor']},
        { path: 'closed', title: 'รายการที่เสร็จสิ้น', ab: 'CC', rolesSub: ['admin', 'helpdesk', 'technical', 'interpreter', 'supervisor']},
    ]
}, {
    path: '/statistics',
    title: 'สถิติ',
    type: 'sub',
    icontype: 'layers',
    collapse: 'statistics',
    roles: ['admin', 'supervisor', 'technical', 'interpreter', 'helpdesk'],
    children: [
        { path: 'all', title: 'สถิติเรื่องทั้งหมด', ab: 'CM', rolesSub: ['admin', 'supervisor', 'technical', 'interpreter', 'helpdesk']},
        { path: 'inprogress', title: 'สถิติเรื่องที่ดำเนินการ', ab: 'CR', rolesSub: ['admin', 'supervisor', 'technical', 'interpreter', 'helpdesk'] },
        { path: 'success', title: 'สถิติเรื่องที่ดำเนินการแล้ว', ab: 'CC', rolesSub: ['admin', 'supervisor', 'technical', 'interpreter', 'helpdesk'] },
        { path: 'processtime', title: 'สถิติเวลาที่ใช้ดำเนินการ', ab: 'PC', rolesSub: ['admin', 'supervisor', 'technical', 'interpreter', 'helpdesk']}
    ]
}, {
    path: '/questions',
    title: 'จัดการคำถามที่พบบ่อย',
    type: 'link',
    icontype: 'content_paste',
    roles: ['admin', 'supervisor'],
    children: []
}, {
    path: '/optionuser',
    title: 'จัดการผู้ใช้งาน',
    type: 'link',
    icontype: 'content_paste',
    roles: ['admin', 'supervisor'],
    children: []
}, {
    path: '/logout',
    title: 'Logout',
    type: 'link',
    icontype: 'lock',
    roles: [],
    children: []
}
];
@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    displayName: string;
    displayProfileImage: string;
    dataJson: any;
    fullName: any;
    rolePar: any;
    tokenStr: any;
    notisMsg: {}
    notisLength: Number = 0;
    // roles_arr=[];
    user: any;
    private roles_arr: any[];
    systemName: string;
    constructor(private auth: AuthService, private HelpdeskCase: HelpdeskCaseService) {
        this.roles_arr = this.auth.getRole();
        this.user       = this.auth.getUser();
        this.tokenStr   = this.auth.getToken()
        this.displayProfileImage = this.user.photo || '../assets/img/default-avatar.png';
        this.displayName = this.user.first_name + ' ' + this.user.last_name;
        this.systemName = environment.environment.NAME || 'Angular Core';
    }
    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };

    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        this.getNotis()
    }

    checkRoles(roles) {
        return roles.some(r => this.roles_arr.indexOf(r) >= 0)
    }

    checkRolesSub(rolesSub) {
        return rolesSub.some(r => this.roles_arr.indexOf(r) >= 0)
    }

    checkAdmin() {
        return this.roles_arr.includes('admin');
    }

    updatePS(): void {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            const ps = new PerfectScrollbar(elemSidebar, { wheelSpeed: 2, suppressScrollX: true });
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }

    getNotis() {
        this.HelpdeskCase.notificationsCase(this.tokenStr).subscribe(res => {
           console.log(res)
           this.notisLength = res['data'].length
           this.notisMsg = res['data']
        });
    }
}
