import { Component, OnInit, Renderer, ViewChild, ElementRef, Directive, EventEmitter, Output } from '@angular/core';
import { ROUTES } from '../.././sidebar/sidebar.component';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {FormControl} from '@angular/forms';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import {
    HelpdeskCaseService,
    HelpdeskCaseWsService,
    AuthService,
    MessageData,
    HelpdeskQuestionsService,
    BroadcastService
} from '../../services'
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';


import { Subject } from 'rxjs/Subject';
import 'rxjs/add/observable/defer';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/multicast';

const misc: any = {
    navbar_menu_visible: 0,
    active_collapse: true,
    disabled_collapse_init: 0,
};

declare var $: any;
@Component({
    selector: 'app-navbar-cmp',
    templateUrl: 'navbar.component.html',
    styleUrls: ['navbar.component.scss'],
})

export class NavbarComponent implements OnInit {
    private listTitles: any[];
    location: Location;
    mobile_menu_visible: any = 0;
    private nativeElement: Node;
    private toggleButton: any;
    private sidebarVisible: boolean;
    private _router: Subscription;
    subscription: Subscription;
    tokenStr: any;
    userId: any;
    rolePar: any;
    notisMsg: {}
    notisLength: Number = 0;
      // websocket
   wsSubject: Observable<MessageData>;
   wsconnect = 'disconnect'
   strTemp: any;
   wsdata;
   statusConnect: string;
   questions: any
   keyword: String = ''
  //
  answers: any

  myControl = new FormControl();
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;

    @ViewChild('app-navbar-cmp') button: any;
    @Output() wsEvents: EventEmitter<any> = new EventEmitter();
    random: number;
    constructor(
        location: Location,
        private renderer: Renderer,
        private element: ElementRef,
        private router: Router,
        private HelpdeskCase: HelpdeskCaseService,
        private wsService: HelpdeskCaseWsService,
        private Auth: AuthService,
        private HelpdeskQuestions: HelpdeskQuestionsService,
        private pubsub: NgxPubSubService,
        private broadcast: BroadcastService) {
        this.location = location;
        this.nativeElement = element.nativeElement;
        this.sidebarVisible = false;
        this.wsconnect = localStorage.getItem('wsStatus');

        }
    minimizeSidebar() {
      const body = document.getElementsByTagName('body')[0];

      if (misc.sidebar_mini_active === true) {
          body.classList.remove('sidebar-mini');
          misc.sidebar_mini_active = false;

      } else {
          setTimeout(function() {
              body.classList.add('sidebar-mini');

              misc.sidebar_mini_active = true;
          }, 300);
      }

      // we simulate the window Resize so the charts will get updated in realtime.
      const simulateWindowResize = setInterval(function() {
          window.dispatchEvent(new Event('resize'));
      }, 180);

      // we stop the simulation of Window Resize after the animations are completed
      setTimeout(function() {
          clearInterval(simulateWindowResize);
      }, 1000);
    }

    hideSidebar() {
      const body = document.getElementsByTagName('body')[0];
      const sidebar = document.getElementsByClassName('sidebar')[0];

      if (misc.hide_sidebar_active === true) {
          setTimeout(function() {
              body.classList.remove('hide-sidebar');
              misc.hide_sidebar_active = false;
          }, 300);
          setTimeout(function () {
              sidebar.classList.remove('animation');
          }, 600);
          sidebar.classList.add('animation');

      } else {
          setTimeout(function() {
            body.classList.add('hide-sidebar');
              // $('.sidebar').addClass('animation');
              misc.hide_sidebar_active = true;
          }, 300);
      }

      // we simulate the window Resize so the charts will get updated in realtime.
      const simulateWindowResize = setInterval(function() {
          window.dispatchEvent(new Event('resize'));
      }, 180);

      // we stop the simulation of Window Resize after the animations are completed
      setTimeout(function() {
          clearInterval(simulateWindowResize);
      }, 1000);
    }

    ngOnInit() {
        this.tokenStr   = this.Auth.getToken()
        this.rolePar    = this.Auth.getRole()
        this.userId     = this.Auth.getUserId()
        // this.getNotis()
        this.listTitles = ROUTES.filter(listTitle => listTitle);

        const navbar: HTMLElement = this.element.nativeElement;
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        if (body.classList.contains('sidebar-mini')) {
            misc.sidebar_mini_active = true;
        }
        if (body.classList.contains('hide-sidebar')) {
            misc.hide_sidebar_active = true;
        }
        this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
          this.sidebarClose();

          const $layer = document.getElementsByClassName('close-layer')[0];
          if ($layer) {
            $layer.remove();
          }
        });

        this.HelpdeskQuestions.getQuestions(this.tokenStr).subscribe(res => {
            this.questions = res;
            this.answers = res;
            console.log(this.questions)

            this.filteredOptions = this.myControl.valueChanges
            .pipe(
              startWith(''),
              map(value => this._filter(value))
            );

        })

        this.wsSubject = <Observable<MessageData>>this.wsService.getSubject();
        this.subscription = this.wsSubject.subscribe(res => {
        if (res['err'] === 'socket close') {
            localStorage.setItem('wsStatus', 'disconnect')
            this.wsconnect = localStorage.getItem('wsStatus');
        } else {
            this.strTemp = res;
            this.wsdata  = JSON.parse(this.strTemp)
            console.log(this.wsdata)
            if (this.rolePar === this.wsdata.contact) {
                console.log('if 1');
                switch (this.wsdata.event) {
                    case 'case-connet':
                        // this.showNotification('bottom', 'right', 'success', 'เชื่อมต่อรับส่งข้อมูลแบบเรียลไทม์');
                        this.getNotis()
                        localStorage.setItem('wsStatus', 'connect')
                        this.wsconnect = localStorage.getItem('wsStatus');
                        // this.wsEvents.emit(this.wsdata);
                        break;
                    case 'new-case-received':
                        this.showNotification('bottom', 'right', 'success', 'นำเข้าข้อมูลกำลังดำเนินการ รหัส : ' + this.wsdata.case);
                        this.getNotis()
                        this.wsEvents.emit(this.wsdata);
                        break;
                    case 'new-case-waiting':
                        this.showNotification('bottom', 'right', 'success', 'นำเข้าข้อมูลที่รอการยืนยัน รหัส : ' + this.wsdata.case);
                        this.getNotis()
                        this.wsEvents.emit(this.wsdata);
                        break;
                    case 'new-case-close':
                        this.showNotification('bottom', 'right', 'success', 'นำเข้าข้อมูลที่ปิดงาน รหัส : ' + this.wsdata.case);
                        this.getNotis()
                        this.wsEvents.emit(this.wsdata);
                        break;
                    default :
                        break;
                    }
            } if (this.rolePar != 'technical' && this.rolePar != 'interpreter') {
                console.log('if 3 : ' + this.rolePar);
                switch (this.wsdata.event) {
                    case 'case-connet':
                        this.getNotis()
                        localStorage.setItem('wsStatus', 'connect')
                        this.wsconnect = localStorage.getItem('wsStatus');
                        // this.wsEvents.emit(this.wsdata);
                        // this.wsEvents.emit('case-connet');
                        break;
                    case 'new-case-received':
                        this.showNotification('bottom', 'right', 'success', 'นำเข้าข้อมูลกำลังดำเนินการ รหัส : ' + this.wsdata.case);
                        this.getNotis()
                        this.wsEvents.emit(this.wsdata);
                        break;
                    case 'new-case-waiting':
                        this.showNotification('bottom', 'right', 'success', 'นำเข้าข้อมูลที่รอการยืนยัน รหัส : ' + this.wsdata.case);
                        this.getNotis()
                        this.wsEvents.emit(this.wsdata);
                        break;
                    case 'new-case-close':
                        this.showNotification('bottom', 'right', 'success', 'นำเข้าข้อมูลที่ปิดงาน รหัส : ' + this.wsdata.case);
                        this.getNotis()
                        this.wsEvents.emit(this.wsdata);
                        break;
                    default :
                        break;
                    }
            }
        }
        });
    }

    searchQuestions () {
        this.answers = []
        this.questions.forEach(q => {
            if ( q.question.includes(this.keyword)) {
                this.answers.push(q)
            }
        });
        console.log(this.answers)
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.options.filter(option => option.toLowerCase().includes(filterValue));
      }

    getNotis() {
        this.HelpdeskCase.notificationsCase(this.tokenStr).subscribe(res => {
           console.log(res)
           this.notiEvent()
           this.notisLength = res['data'].length
           this.notisMsg = res['data']
        });
    }

    testEvent() {
        console.log('click')
        // this.random = Math.floor(Math.random() * 100);
        // this.pubsub.publishEvent('event-number', this.random);
        this.broadcast.sendMessage('test')
        // const source = Observable.defer(() => Observable.of(
        //     Math.floor(Math.random() * 100)
        //   ));

        //   const m = source.multicast(new Subject<number>());
        //     m.subscribe(this.observer('a'));
        //     // m.subscribe(this.observer('b'));
        //     m.connect();

        //     const p = source.publish().refCount();
        //     p.subscribe(this.observer('p1'));
            // p.subscribe(observer("b"));
            // setTimeout(() => p.subscribe(observer("c")), 10);
    }

    observer(name: string) {
        return {
          next: (value: number) => console.log(`observer ${name}: ${value}`),
          complete: () => console.log(`observer ${name}: complete`)
        };
    }

    notiEvent() {
        const element = document.getElementById('noti');
        element.classList.add('test')
        setTimeout(function() { element.classList.remove('test')}, 2000);
    }

    pushRouter(id) {
        this.router.navigate(['/case/', id]);
    }

    onResize(event) {
      if ($(window).width() > 991) {
        return false;
      }
      return true;
    }
    sidebarOpen() {
      var $toggle = document.getElementsByClassName('navbar-toggler')[0];
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function() {
            toggleButton.classList.add('toggled');
        }, 500);
        body.classList.add('nav-open');
        setTimeout(function() {
            $toggle.classList.add('toggled');
        }, 430);

        var $layer = document.createElement('div');
        $layer.setAttribute('class', 'close-layer');


        if (body.querySelectorAll('.main-panel')) {
            document.getElementsByClassName('main-panel')[0].appendChild($layer);
        }else if (body.classList.contains('off-canvas-sidebar')) {
            document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
        }

        setTimeout(function() {
            $layer.classList.add('visible');
        }, 100);

        $layer.onclick = function() { //asign a function
          body.classList.remove('nav-open');
          this.mobile_menu_visible = 0;
          this.sidebarVisible = false;

          $layer.classList.remove('visible');
          setTimeout(function() {
              $layer.remove();
              $toggle.classList.remove('toggled');
          }, 400);
        }.bind(this);

        body.classList.add('nav-open');
        this.mobile_menu_visible = 1;
        this.sidebarVisible = true;
    };
    sidebarClose() {
      var $toggle = document.getElementsByClassName('navbar-toggler')[0];
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton.classList.remove('toggled');
        var $layer = document.createElement('div');
        $layer.setAttribute('class', 'close-layer');

        this.sidebarVisible = false;
        body.classList.remove('nav-open');
        // $('html').removeClass('nav-open');
        body.classList.remove('nav-open');
        if ($layer) {
            $layer.remove();
        }

        setTimeout(function() {
            $toggle.classList.remove('toggled');
        }, 400);

        this.mobile_menu_visible = 0;
    };
    sidebarToggle() {
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    }

    getTitle() {
        let titlee: any = this.location.prepareExternalUrl(this.location.path());
        for (let i = 0; i < this.listTitles.length; i++) {
            if (this.listTitles[i].type === "link" && this.listTitles[i].path === titlee) {
                return this.listTitles[i].title;
            } else if (this.listTitles[i].type === "sub") {
                for (let j = 0; j < this.listTitles[i].children.length; j++) {
                    let subtitle = this.listTitles[i].path + '/' + this.listTitles[i].children[j].path;
                    if (subtitle === titlee) {
                        return this.listTitles[i].children[j].title;
                    }
                }
            }
        }
        return 'Dashboard';
    }
    getPath() {
        return this.location.prepareExternalUrl(this.location.path());
    }

    // Notification
showNotification(from: any, align: any, noti: string, data: any) {
    this.statusConnect = localStorage.getItem('wsStatus');
    const type = ['', 'info', 'success', 'warning', 'danger', 'rose', 'primary'];
    let messagenoti = ''
    const color = type.indexOf(noti);
    if (noti === 'danger') {
      messagenoti = '<b>Service Close Please Wait.... ' + data + '</b>';
    } else {
      messagenoti = '<b>' + data + '</b>';
    }
    $.notify({
        icon: 'notifications',
        message: messagenoti
    }, {
        type: type[color],
        timer: 3000,
        placement: {
            from: from,
            align: align
        },
        template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">' +
          '<i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0"' +
          'aria-valuemax="100" style="width: 0%;"></div>' +
          '</div>' +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  } // END Notification

}
