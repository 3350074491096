import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment'

@Injectable()
export class HelpdeskStatisticsService {

  constructor(private http: HttpClient, public router: Router) { }

  getStatisticsAll(token, query) {
    const headers = new HttpHeaders({'Authorization': token});
    return this.http.get( environment.environment.API_BLACKEND + '/api/statistics/' + query.action + '?start_date=' + query.start_date
                          + '&end_date=' + query.end_date + '&year=' + query.year + '&month=' + query.month
                          + '&type=' + query.type + '&action=' + query.action, {headers});
  }

  // getStatisticsInprogress(token, query) {
  //   const headers = new HttpHeaders({'Authorization': token});
  //   return this.http.get( environment.API_BLACKEND + '/api/statistics/' + query.action + '?start_date=' + query.start_date
  //                         + '&end_date=' + query.end_date + '&year=' + query.year + '&month=' + query.month
  //                         + '&type=' + query.type + '&action=' + query.action, {headers});
  // }
}
