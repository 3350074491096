import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment'

@Injectable()
export class HelpdeskStorgeService {

  constructor(private http: HttpClient, public router: Router, private route: ActivatedRoute) { }

  patchStorgeById(token, fileFormdata, id) {
    const headers = new HttpHeaders({'Authorization': token});
    // return this.http.patch( environment.API_BLACKEND + '/api/storge/' + id, fileFormdata, {headers});
    const req = new HttpRequest<FormData>('PATCH', environment.environment.API_BLACKEND + '/api/storge/' + id, fileFormdata,
    {headers, reportProgress: true})
    return req;
  }

  DeleteFile(token, body) {
    const headers = new HttpHeaders({'Authorization': token});
    return this.http.post( environment.environment.API_BLACKEND + '/api/storge/file', body
    , {headers});
  }

  DeleteFileById(token, body, id) {
    const headers = new HttpHeaders({'Authorization': token});
    return this.http.put( environment.environment.API_BLACKEND + '/api/storge/' + id, body, {headers});
  }

}
