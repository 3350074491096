import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AuthGuardService } from './services/auth-guard.service';
export const AppRoutes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },
    {
        path: '',
        component: AdminLayoutComponent,
        canActivate: [AuthGuardService],
        children: [
            {
                path: '',
                loadChildren: './dashboard/dashboard.module#DashboardModule'
            }, {
                path: 'users',
                loadChildren: './users/users.module#UsersModule'
            }, {
                path: '',
                loadChildren: './profile/profile.module#ProfileModule'
            }, {
                path: 'optionuser',
                loadChildren: './agent/agent.module#AgentModule'
            }, {
                path: 'case',
                loadChildren: './detail-case/detail-case.module#DetailCaseModule'
            }, {
                path: 'questions',
                loadChildren: './questions/questions.module#QuestionsModule'
            }, {
                path: 'statistics',
                loadChildren: './statistics/statistics.module#StatisticsModule'
            }
        ]
    }, {
        path: '',
        component: AuthLayoutComponent,
        children: [{
            path: '',
            loadChildren: './pages/pages.module#PagesModule'
        }]
    }
];
