import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import {Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment'

@Injectable()
export class UserService {

  constructor(private http: HttpClient, public router: Router, private route: ActivatedRoute) { }

  getUser(params, token) {
    const headers = new HttpHeaders({'Authorization': token});
    const query_string = [];
    Object.keys(params).forEach(function(k) {
        query_string.push(k + '=' + params[k]);
    });
    return this.http.get( environment.environment.API_BLACKEND + '/api/agent/?' + query_string.join('&'), {headers});
  }

  getUserByID(id, token) {
    const headers = new HttpHeaders({'Authorization': token});
    return this.http.get( environment.environment.API_BLACKEND + '/api/agent/' + id, {headers});
  }

  getProfile(id, token) {
    const headers = new HttpHeaders({'Authorization': token});
    // console.log(id)
    // console.log(token)
    return this.http.get( environment.environment.API_BLACKEND + '/api/agent/' + id, {headers});
  }

  createUser(body, token) {
    // const headers = new HttpHeaders({'Authorization': token});
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    return this.http.post( environment.environment.API_BLACKEND + '/api/agent', body, {headers});
  }

  updateProfile(body, token) {
    const headers = new HttpHeaders({'Authorization': token});
    return this.http.put( environment.environment.API_BLACKEND + '/api/agent', body, {headers});
  }

  updateUser(id, body, token) {
    const headers = new HttpHeaders({'Authorization': token});
    return this.http.put( environment.environment.API_BLACKEND + '/api/user/' + id, body, {headers});
  }

  deleteUser(id, token) {
    const headers = new HttpHeaders({'Authorization': token});
    return this.http.delete( environment.environment.API_BLACKEND + '/api/user/' + id, {headers});
  }

  getUserGroup(token) {
    const headers = new HttpHeaders({'Authorization': token});
    return this.http.get( environment.environment.API_BLACKEND + '/api/usergroup', {headers});
  }

  getInterpreters(params, token) {
    const headers = new HttpHeaders({'Authorization': token});
    const query_string = [];
    Object.keys(params).forEach(function(k) {
        query_string.push(k + '=' + params[k]);
    });
    return this.http.get( environment.environment.API_BLACKEND + '/api/getInterpreterForGetJob/?' + query_string.join('&'), {headers});
  }

}
