import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Router, ActivatedRoute } from '@angular/router';
import {environment} from '../../environments/environment';

@Injectable()
export class AuthService {
  private returnUrl;
  currentUser;
  currentDataUserJson;
  currentRole;
  currentToken;
  constructor(private http: HttpClient, public router: Router, private route: ActivatedRoute) {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
    console.log(this.returnUrl);
  }

  login(username: string, password: string) {
    return this.http.post(environment.environment.API_BLACKEND + '/api/singin', {
       username: username,
       password: password
     });
  }

  logout() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentToken');
    localStorage.removeItem('currentRole');
  }

  getUser() {
    this.currentDataUserJson = JSON.parse(localStorage.getItem('currentUser'));
    return this.currentDataUserJson;
  }

  getUserId() {
    const currentUserId = JSON.parse(localStorage.getItem('currentUser'));
    return currentUserId['id'];
  }

  getRole() {
    const roles_arr = [];
    this.currentRole = JSON.parse(localStorage.getItem('currentRole'));
      this.currentRole['role'].forEach(r => {
        roles_arr.push(r.title)
      });
    return roles_arr[0];
  }

  getToken() {
    this.currentToken = JSON.parse(localStorage.getItem('currentUser'));
    return 'Bearer ' + this.currentToken['token'];
  }
}
