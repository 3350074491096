import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment'


@Injectable()
export class HelpdeskQuestionsService {

  constructor(private http: HttpClient, public router: Router, private route: ActivatedRoute) { }

  getQuestions(token) {
    const headers = new HttpHeaders({'Authorization': token});
    return this.http.get( environment.environment.API_BLACKEND + '/api/questions', {headers});
  }

  getQuestionsById(token, id) {
    const headers = new HttpHeaders({'Authorization': token});
    return this.http.get( environment.environment.API_BLACKEND + '/api/questions/' + id, {headers});
  }

  patchQuestionsById(token, data) {
    const headers = new HttpHeaders({'Authorization': token});
    return this.http.patch( environment.environment.API_BLACKEND + '/api/questions/' + data.fid, data, {headers});
  }

  postQuestion(token, data) {
    const headers = new HttpHeaders({'Authorization': token});
    return this.http.post( environment.environment.API_BLACKEND + '/api/questions', data, {headers});
  }

  deleteQuestion(token, id) {
    const headers = new HttpHeaders({'Authorization': token});
    return this.http.delete( environment.environment.API_BLACKEND + '/api/questions/' + id, {headers});
  }
}
