import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment'

@Injectable()
export class HelpdeskSettingService {
  public search_type = [{
    type: 'month',
    title: 'แสดงแบบรายเดือน'
  } , {
    type: 'year',
    title: 'แสดงแบบรายปี'
  } , {
    type: 'day',
    title: 'แสดงแบบช่วงเวลา'
  }]
  public search_month = [{
    month: '01',
    title: 'มกราคม'
  }, {
    month: '02',
    title: 'กุมภาพันธ์'
  }, {
    month: '03',
    title: 'มีนาคม'
  }, {
    month: '04',
    title: 'เมษายน'
  }, {
    month: '05',
    title: 'พฤษภาคม'
  }, {
    month: '06',
    title: 'มิถุนายน'
  }, {
    month: '07',
    title: 'กรกฏาคม'
  }, {
    month: '08',
    title: 'สิงหาคม'
  }, {
    month: '09',
    title: 'กันยายน'
  }, {
    month: '10',
    title: 'ตุลาคม'
  }, {
    month: '11',
    title: 'พฤศจิกายน'
  }, {
    month: '12',
    title: 'ธันวาคม'
  }]
  constructor(private http: HttpClient, public router: Router, private route: ActivatedRoute) { }

  getSettingOfficer(token) {
    const headers = new HttpHeaders({'Authorization': token});
    return this.http.get( environment.environment.API_BLACKEND + '/api/setting/officer', {headers});
  }

  getSettingChannal(token) {
    const headers = new HttpHeaders({'Authorization': token});
    return this.http.get( environment.environment.API_BLACKEND + '/api/setting/channel', {headers});
  }

  getSetting(token) {
    const headers = new HttpHeaders({'Authorization': token});
    return this.http.get( environment.environment.API_BLACKEND + '/api/setting', {headers});
  }


}
